export const baseurl = 'https://api.prokpi.viserx.dev/api'
// export const baseurl = 'https://api.demo.prokpi.net/api'
// export const baseurl = 'https://api.app.prokpi.net/api'
// export const baseurl = 'http://kpi-module.test/api'

// export const baseurl = 'https://api-kpi.seoviserx.com/api'


export const PUSHER_APP_ID = '1543662'
export const PUSHER_APP_KEY = 'df38110fa36d67c5ab79'
export const PUSHER_APP_SECRET = '6c4f266e03e180acf1c2'
export const PUSHER_APP_CLUSTER = 'ap1'

export const CAPTCHA_SITEKEY = "6LfvDlEnAAAAAJxgz5Mhzaoqb9EPfVzKk5PvCgLV"

export const VIDEO_TUTORIAL_URL = 'https://www.youtube.com/watch?v=QuMz-TsGhXo&list=PLT79q8xBIzXJDSFErf71_vR76oShY10a_'
export const DOCUMENTATION_LINK = 'https://docs.google.com/document/d/1xUBBxdjaLZZQWEihzGBX_xxzMmvxauD3iXO1PI_2VxY/edit?usp=sharing'

export const XDESK_SECRET_KEY = "2DP2NhWTdtdt2WY57V9POWP45QbSytzs"